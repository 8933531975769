import { UserType } from "../../services/userService";

export type Token = {
  userId: string | null;
  email: string | null;
  firstName: string | null;
  access_token: string | null;
  client: string | null;
  geotabSessionId: string | null;
  geotabServer: string | null;
  expires_in: number | null;
  userType: UserType;
};

export function getDefaultToken(email: string | null = null): Token {
  return {
    userId: null,
    email: email,
    firstName: null,
    access_token: null,
    client: null,
    geotabSessionId: null,
    geotabServer: null,
    expires_in: null,
    userType: UserType.Undefined
  };
}