import axios from 'axios';
import { AppConsts } from '../components/const';
import { clearSession } from '../components/session/session';

var http = setupAxios();

function setHttpToken(token: string | null)
{
  if (token != null)
    axios.defaults.headers.common = {'Authorization': `BEARER ${token}`}
  
  http = setupAxios(token != null);
}

function setupAxios(setupInterceptors?: boolean) {
  let httpAxios = axios.create({
    baseURL: AppConsts.ServiceBaseUrl,
    timeout: 30000
  });

  if (!setupInterceptors)
    return httpAxios;

  httpAxios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.isAxiosError && error.response) {
        if (error.response.data && error.response.data.Error)
          return Promise.reject({ Error: error.response.data.Error });

        if (error.response.status == 401)
        {
          if (error.response.statusText == "Unauthenticated")
          {
            clearSession();
            window.location.reload(); 
          }

          return Promise.reject({ Error: 'Unauthorized' });
        }

        if (error.response.status == 404)
          return Promise.reject({ Error: 'Not Found' });
      }

      return Promise.reject({ Error: error });
    }
  );
  
  return httpAxios;
}

export { http, setHttpToken };

export interface ApiResponse<T> {
  Id: string;
  Data: T;
  Total: number;
  Error: any;
}

export interface ApiSimpleResponse {
  Id: string;
  Error: any;
}