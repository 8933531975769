import Noty from 'noty';

export default abstract class Notification {
  public static Success(message: string) {
    new Noty({ theme: 'bootstrap-v4', text: message, type: 'success', timeout: 5000, progressBar: true }).show();
  };
    
  public static Warning(message: string) {
    console.log('Warning: ' + message); 
    new Noty({ theme: 'bootstrap-v4', text: message, type: 'warning', timeout: 5000, progressBar: true }).show();
  };

  public static Error(message: string) {
    console.log('Error: ' + message); 
    new Noty({ theme: 'bootstrap-v4', text: message, type: 'error', timeout: 5000, progressBar: true }).show();
  };
}