import React, { useEffect, useState, useRef } from 'react';
import { StaticContext, useHistory } from 'react-router';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { SessionAction } from '../../components/session/actions';
import Loading from '../../components/loading';
import { useStore } from '../../components/appProvider';
import AuthenticationService from '../../services/authenticationService';

type LoginLocationState = {
  referer: Location;
};

export default function Login(props: RouteComponentProps<{}, StaticContext, LoginLocationState>) {
  const initialLoad = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showDatabaseInput, setShowDatabaseInput] = useState(false);
  const [redirect, setRedirect] = useState('/');
  const { state, dispatch } = useStore();

  const history = useHistory();
  const { admin } = useParams();

  const schema = Yup.object().shape({
    Email: Yup.string().required('Email is required'),
    Password: Yup.string().required('Password is required')
  });

  useEffect(() => {
    setShowDatabaseInput(admin === 'admin');

    if (initialLoad.current)
    {
      initialLoad.current = false;

      if (props.location.state && props.location.state.referer)
        setRedirect(props.location.state.referer.pathname);

      if (redirect === '/Login')
        setRedirect('/');

      if (state.session.isAuthenticated)
        history.push(redirect);
    }
  }, [props.location.state, state.session.isAuthenticated, history, redirect, admin]);

  async function submit(values: any, { setErrors, setSubmitting }: any) {
    setIsLoading(true);

    let result = await AuthenticationService.authenticate({
      email: values.Email,
      password: values.Password,
      database: values.Database
    }); 
    
    setIsLoading(false);
    setSubmitting(false);

    if (result.error) {
      setErrors({ error: result.errorMessage });
    }
    else {
      dispatch({ type: SessionAction.Login, payload: { token: result.token, rememberMe: values.RememberMe } });
      history.push(redirect);
    }
  }

  return (
    <>
      { isLoading ? <Loading /> : <></> }
      <div className='row justify-content-center'>
        <div className='col-sm-12 mt-3' style={{maxWidth: '400px'}}>
          <div className='card card-shadowed'>
            <div className='card-header'>
              Login to my account
            </div>
            <div className='card-body'>
              <Formik
                initialValues={{ Email: state.session.auth.email == null ? '' : state.session.auth.email, Password: '', Database: '', RememberMe: state.session.rememberMe }}
                validationSchema={schema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={submit}
              >
                {({ isSubmitting, isValid, errors }) => (
                  <Form className='forms'>
                    <div className={`validation-summary-errors text-danger ${isValid ? 'd-none' : ''}`}>
                      <span>Please look at the error(s) below:</span>
                      <ul>
                        {Object.values(errors).map((msg, i) => (
                          <li key={i}>{msg}</li>
                        ))}
                      </ul>
                    </div>
                    <div className='form-group'>
                      <label>Email</label>
                      <Field type='string' name='Email' className='form-control col-12' autoFocus='' />
                    </div>
                    <div className='form-group'>
                      <label>Password</label>
                      <Field type='password' name='Password' className='form-control col-12' />
                    </div>
                    <div className='form-group mb-4' style={{ display: (!showDatabaseInput ? 'none' : 'inherit')}}>
                      <label>Client</label>
                      <Field type='string' name='Database' className='form-control col-12' />
                    </div>
                    <div className='form-check mb-4'>
                      <Field type='checkbox' className='form-check-input' name='RememberMe' />
                      <label className='form-check-label'>Remember me</label>
                    </div>
                    <button type='submit' disabled={isSubmitting} className='btn btn-primary btn-bold btn-block'>LOGIN</button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}