import React, { useState, useEffect } from 'react';

export default function Loading() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var timer = setTimeout(() => setIsLoading(true), 200);

    return () => { clearTimeout(timer); setIsLoading(false); };
  }, []);

  return (
    isLoading ? 
      <div id="loader-container">
        <div className="spinner-border" role="status">
          <span className="sr-only no-print">Loading...</span>
        </div>
      </div> :
      <></>
  );
}