import React, { useState, useEffect, useRef } from 'react';
import Loading from '../../components/loading';
import Error from '../../components/error';
import Notification from '../../components/notification';
import groupService, { GroupModel } from '../../services/groupService';
import clearanceService, { ClearanceModel } from '../../services/clearanceService';

export default function AdminHome()
{
  const initialLoad = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([] as GroupModel[]);
  const [clearances, setClearances] = useState([] as ClearanceModel[]);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    if (initialLoad.current)
    {
      initialLoad.current = false;

      getGroups();
      getSecurityClearances();
    }
  });

  function getGroups()
  {
    groupService.getAll()
      .then((response) => { setGroups(response.Data) })
      .catch((response) => setError(response.Error))
      .finally(() => setIsLoading(false));
  }

  function addDefaultGroups()
  {
    setIsLoading(true);

    groupService.addDefault()
      .then(() => { Notification.Success("Default groups added"); getGroups(); })
      .catch((response) => Notification.Error(response.Error))
      .finally(() => setIsLoading(false));
  }

  function getSecurityClearances()
  {
    clearanceService.getAll()
      .then((response) => { setClearances(response.Data) })
      .catch((response) => setError(response.Error))
      .finally(() => setIsLoading(false));
  }

  function addDefaultSecurityClearances()
  {
    setIsLoading(true);

    clearanceService.addDefault()
      .then(() => { Notification.Success("Default security clearances added"); getSecurityClearances(); })
      .catch((response) => Notification.Error(response.Error))
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      { isLoading ? <Loading /> : <></> }
      <div className='container-page px-0'>
        <div className='d-flex justify-content-between flex-wrap mb-3 no-print'>
          <h2 className='mb-2'>Client Settings</h2>
            <div className="col-auto">
              <div className="row">
                <div className="col col-sm-auto mx-1 px-0">
                  <button className="btn btn-primary btn-block mb-2 text-nowrap" onClick={addDefaultGroups}>
                    Add Default Groups
                  </button>
                </div>
                <div className="col col-sm-auto mx-1 px-0">
                  <button className="btn btn-primary btn-block mb-2 text-nowrap" onClick={addDefaultSecurityClearances}>
                    Add Default Security Clearances
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div> 
      {
        error != null ?
          <Error message='Unable to load settings' /> :
          <>
            <div className='container-page px-0'>
              <div className='row'>
                <div className='col-lg-6 mb-3'>
                  <div className='table-responsive wrapper p-3'>
                    <table className='table table-sm table-striped'>
                      <thead className='thead-dark'>
                        <tr>
                          <th>Groups</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          groups ?
                            groups.map((group) => <GroupRow key={group.Id} group={group} />) :
                            <></>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='col-lg-6 mb-3'>
                  <div className='table-responsive wrapper p-3'>
                    <table className='table table-sm table-striped'>
                      <thead className='thead-dark'>
                        <tr>
                          <th>Security Clearances</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          clearances ?
                          clearances.map((clearance) => <ClearanceRow key={clearance.Id} clearance={clearance} />) :
                            <></>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  );
}

interface IGroupRowProps {
  group: GroupModel
}

function GroupRow({ group }: IGroupRowProps) {
  return (
    <tr>
      <td>{group.Name}</td>
    </tr>
  )
}

interface IClearanceRowProps {
  clearance: ClearanceModel
}

function ClearanceRow({ clearance }: IClearanceRowProps) {
  return (
    <tr>
      <td>{clearance.Name}</td>
    </tr>
  )
}