import { Token } from "./token";
import { ActionMap } from "../util";

export enum SessionAction {
  Login = 'SESSION_LOGIN',
  Logout = 'SESSION_LOGOUT'
}

type SessionPayload = {
  [SessionAction.Logout]: { };
  [SessionAction.Login]: {
    token: Token;
    rememberMe: boolean;
  };
};

export type SessionActions = ActionMap<SessionPayload>[keyof ActionMap<SessionPayload>];