import React, { Component, ErrorInfo  } from 'react';
import Error from './error';

type ErrorBoundaryState = { hasError: boolean };

export default class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <Error message='Something went wrong. Please refresh the page.' />;
    }

    return this.props.children;
  }
};