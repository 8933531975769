import 'bootstrap/dist/css/bootstrap.css';
import 'noty/lib/noty.css';
import 'noty/lib/themes/bootstrap-v4.css';
import './styles/App.css';

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthorizedRoute from './components/authorizedRoute';
import Header from './views/shared/header';
import Navigation from './views/shared/navigation';
import Footer from './views/shared/footer';
import Error from './views/error/index';
import Map from './views/map/index';
import Login from './views/login/index';
import Users from './views/user/index';
import UserDetails from './views/user/details';
import AddUser from './views/user/add';
import { UserType } from './services/userService';
import AdminHome from './views/admin';

export default function App() {
  return (
    <>
      <Header />
      <Navigation />
      <main className='main mt-3'>
        <div className='container-fluid'>
          <Switch>
            <Route path='/login/:admin?' component={Login} />
            <Route path='/error' component={Error} />
            <AuthorizedRoute path='/admin' component={AdminHome} userTypeList={[UserType.GlobalAdmin]} />
            <AuthorizedRoute path='/users/details/:userId' component={UserDetails} />
            <AuthorizedRoute path='/users/add' component={AddUser} userTypeList={[UserType.GeotabAdmin, UserType.GlobalAdmin]} />
            <AuthorizedRoute path='/users' component={Users} userTypeList={[UserType.GeotabAdmin, UserType.GlobalAdmin]} />
            <AuthorizedRoute path='/' component={Map} forceRefresh={true} />
          </Switch>
        </div>
      </main>
      <Footer />
    </>
  );
}