import React from 'react';

type ErrorProps = { 
  message: string,
  error?: any 
};

export default function Error({ message, error }: ErrorProps) {
  if (error && !(error instanceof Object) && error.length > 0)
    message += ' (' + error + ')';

  return (
    <div className='container-page px-0'>
      <div className='alert alert-danger mt-3' role='alert'>
        {message}
      </div>
    </div>
  );
};