import { http } from './httpService';
import { Token } from '../components/session/token';

export interface AuthenticationModel {
  email: string;
  password: string;
  database: string;
}

export interface AuthenticationResultModel {
  token: Token;
  error: boolean | null;
  errorMessage: string | null;
}

class AuthenticationService {
  public async authenticate(model: AuthenticationModel): Promise<AuthenticationResultModel> {
    let params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', model.email);
    params.append('password', model.password);

    return await http.post('api/Authenticate?m=GeotabCredentials&database=' + model.database, params)
      .then(function (response) 
      {
        if (response.data.hasOwnProperty('access_token')) {
          return { token: JSON.parse(JSON.stringify(response.data)) as Token } as AuthenticationResultModel;
        }

        return { error: true, errorMessage: 'Something went wrong' } as AuthenticationResultModel;
      })
      .catch(function (error) 
      {
        if (error.response && error.response.data && error.response.data.error_description) {
          return { error: true, errorMessage: error.response.data.error_description } as AuthenticationResultModel;
        }

        return { error: true, errorMessage: 'Something went wrong'} as AuthenticationResultModel;
      });
    }
}

export default new AuthenticationService();