import React, { useState, useEffect, useRef } from 'react';
import Loading from '../../components/loading';
import userService, { UserModel, UserTypeLabel } from '../../services/userService';
import Error from '../../components/error';
import { Link } from 'react-router-dom';

export default function Users()
{
  const initialLoad = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([] as UserModel[]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (initialLoad.current)
    {
      initialLoad.current = false;

      userService.getAll()
        .then((response) => { setUsers(response.Data) })
        .catch((response) => setError(response.Error))
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <>
      <div className='container-page px-0'>
        <div className='d-flex justify-content-between flex-wrap mb-3 no-print'>
          <h2 className='mb-2'>Users</h2>
            <div className="col-auto">
              <div className="row">
                <div className="col col-sm-auto mx-1 px-0">
                  <Link to={`/users/add`} className="btn btn-success btn-block mb-2 text-nowrap">Add User</Link>
                </div>
              </div>
            </div>
        </div>
      </div>
      {
        isLoading ?
          <Loading /> :
        error != null ?
          <Error message='Unable to load users' /> :
          <>
            <div className='container-page px-0'>
              <div className='row'>
                <div className='col-lg-12 mb-3'>
                  <div className='table-responsive wrapper p-3'>
                    <table className='table table-sm table-striped'>
                      <thead className='thead-dark'>
                        <tr>
                          <th>User</th>
                          <th>First</th>
                          <th>Last</th>
                          <th>Role</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          users ?
                            users.map((user) => <UserRow key={user.Id} user={user} />) :
                            <></>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  );
}

interface IUserRowProps {
  user: UserModel
}

function UserRow({ user }: IUserRowProps) {
  return (
    <tr>
      <td>{user.Email}</td>
      <td>{user.FirstName}</td>
      <td>{user.LastName}</td>
      <td>{UserTypeLabel.get(user.Type)}</td>
      <td>
        <Link to={`/users/details/${user.Id}`}>Edit</Link>
      </td>
    </tr>
  )
}