import React, { createContext, useContext, useReducer, Dispatch, useEffect, useRef } from 'react';
import { SessionReducer, InitialStateType } from './session/sessionReducer';
import { loadSession } from './session/session';
import { SessionActions } from './session/actions';

const initialState: InitialStateType = {
  session: loadSession()
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<SessionActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = ({ session }: InitialStateType, action: SessionActions) => ({
  session: SessionReducer(session, action)
});

export function AppProvider(props: any) {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }} {...props} />
  );
}

export function useStore() {
  return useContext(AppContext);
}