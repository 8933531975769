import React from 'react';

export default function Footer() {
  return (
    <footer className='footer no-print mt-3'>
      <div className='container-fluid'>
          <span className='text-muted my-2 mx-2'>
              © MPAQ Automation Inc.
          </span>
      </div>
    </footer>
  );
}