import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { useStore } from '../../components/appProvider';
import Loading from '../../components/loading';
import Error from '../../components/error';
import UserSettings from './components/settings';
import UserPassword from './components/password';
import UserPasswordAdmin from './components/passwordAdmin';
import ArchiveUser from './components/archive';
import userService, { UserModel, UserType } from '../../services/userService';

export default function UserDetails()
{
  const [isLoading, setIsLoading] = useState(true);
  const [tabKey, setTabKey] = useState('Settings');
  const [user, setUser] = useState({} as UserModel);
  const [error, setError] = useState(null);

  const { state } = useStore();
  const { userId } = useParams();

  useEffect(() => {
    userService.get(userId)
      .then((response) => { setUser(response.Data) })
      .catch((response) => { setError(response.Error) })
      .finally(() => setIsLoading(false));

      return () => { reset(); };
  }, [userId]);

  function reset()
  {
    setIsLoading(true);
    setUser({} as UserModel);
    setError(null);
    setTabKey('Settings');
  };

  function handleTabSelect(key: any)
  {
    setTabKey(key);
  };

  const isSelf = state.session.auth.userId == userId;

  const showChangePassword = isSelf;
  const showSetPassword = !isSelf && (state.session.auth.userType == UserType.GeotabAdmin || state.session.auth.userType == UserType.GlobalAdmin);
  const showArchiveUser = !isSelf && (state.session.auth.userType == UserType.GeotabAdmin || state.session.auth.userType == UserType.GlobalAdmin);

  return (
    <>
      {
        isLoading ?
          <Loading /> :
        error != null ?
          <Error message='Unable to load user' error={error} /> :
          <>
            <div className='container-page px-0'>
              <div className='d-flex justify-content-between flex-wrap mb-3 no-print'>
                <h2 className='mb-2'>{user.FirstName} {user.LastName}</h2>
              </div>
            </div>
            <div className='container-page px-0'>
              <Tab.Container activeKey={tabKey} onSelect={handleTabSelect}>
                <Row>
                  <Col sm={3}>
                    <Nav variant='pills' className='flex-column'>
                      <Nav.Item>
                        <Nav.Link eventKey='Settings'>Settings</Nav.Link>
                      </Nav.Item>
                      {
                        showChangePassword ?
                          <Nav.Item>
                            <Nav.Link eventKey='ChangePassword'>Change Password</Nav.Link>
                          </Nav.Item> : 
                          <></>
                      }
                      {
                        showSetPassword ?
                          <Nav.Item>
                            <Nav.Link eventKey='SetPassword'>Set Password</Nav.Link>
                          </Nav.Item> : 
                          <></>
                      }
                      {
                        showArchiveUser ?
                          <Nav.Item>
                            <Nav.Link eventKey='ArchiveUser'>Archive User</Nav.Link>
                          </Nav.Item> : 
                          <></>
                      }
                    </Nav>
                  </Col>
                  <Col sm={9} className='wrapper' style={{borderLeft: '3px solid rgb(235, 236, 240)'}}>
                    <Tab.Content>
                      <Tab.Pane eventKey='Settings'>
                        <UserSettings user={user} />
                      </Tab.Pane>
                      {
                        showChangePassword ?
                          <Tab.Pane eventKey='ChangePassword'>
                            <UserPassword userId={user.Id} tabSelect={handleTabSelect} />
                          </Tab.Pane> : 
                          <></>
                      }
                      {
                        showSetPassword ?
                          <Tab.Pane eventKey='SetPassword'>
                            <UserPasswordAdmin userId={user.Id} tabSelect={handleTabSelect} />
                          </Tab.Pane>: 
                          <></>
                      }
                      {
                        showArchiveUser ?
                          <Tab.Pane eventKey='ArchiveUser'>
                            <ArchiveUser userId={user.Id} userName={user.FirstName + ' ' + user.LastName} />
                          </Tab.Pane>: 
                          <></>
                      }
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
        </>
      }
    </>
  );
}