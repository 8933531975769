import { Moment, utc } from "moment";
import { Token, getDefaultToken } from "./token";
import { setHttpToken } from '../../services/httpService';

const authTokenStorageName = 'AuthToken';

export type Session = {
  auth: Token;
  expiry: Moment | null;
  isAuthenticated: boolean;
  rememberMe: boolean;
};

function getDefaultSession(): Session {
  return {
    auth: getDefaultToken(),
    expiry: null,
    isAuthenticated: false,
    rememberMe: false
  };
}

export function loadSession(): Session {
  const session: Session = getDefaultSession();

  const storage = window.localStorage.getItem(authTokenStorageName);
  if (storage != null) {
    const storedSession = JSON.parse(storage) as Session;
    session.auth.email = storedSession.auth.email;
    session.rememberMe = storedSession.rememberMe;

    if (storedSession.auth.access_token != null && storedSession.expiry != null && utc(storedSession.expiry) > utc().add(-8, 'hours')) {
      session.auth = storedSession.auth;
      session.expiry = storedSession.expiry;
      session.isAuthenticated = true;
      session.auth.userType = storedSession.auth.userType;

      saveSession(session);
    }
  }

  return session;
};

export function saveSession(session: Session) {
  setHttpToken(session.auth.access_token);
  window.localStorage.setItem(authTokenStorageName, JSON.stringify(session));
}

export function clearSession(session?: Session) {
  if (session == null)
  {
    const storage = window.localStorage.getItem(authTokenStorageName);
    if (storage != null) {
      session = JSON.parse(storage) as Session;
      
      session.auth = getDefaultToken(session.rememberMe ? session.auth.email : null);
      session.expiry = null;
      session.isAuthenticated = false;
    }
  }

  if (session != null)
    saveSession(session);
}