import { http, ApiResponse, ApiSimpleResponse } from './httpService';

export interface GroupModel {
  Id: string;
  Name: string;
};

class GroupService {
  public async getAll(): Promise<ApiResponse<GroupModel[]>> {
    return (await http.get<ApiResponse<GroupModel[]>>('api/groups/getAll')).data;
  };
  public async addDefault(): Promise<ApiSimpleResponse> {
    return (await http.post<ApiSimpleResponse>('api/groups/addDefault')).data;
  };
}

export default new GroupService();