import { http, ApiResponse } from './httpService';

export enum UserType {
  Undefined = 0,
  GeotabAdmin = 1,
  GeotabDriver = 2,
  GeotabDispatcher = 3,
  GlobalAdmin = 5
};

export const UserTypeLabel = new Map<number, string>([
  [UserType.Undefined, 'Not Set'],
  [UserType.GeotabAdmin, 'Admin'],
  [UserType.GeotabDriver, 'Driver'],
  [UserType.GeotabDispatcher, 'Dispatcher']
]);

export function UserTypeParse(userType: any) {
  switch (userType) {
    case '1':
      return UserType.GeotabAdmin
    case '2':
      return UserType.GeotabDriver
    case '3':
      return UserType.GeotabDispatcher
    case '5':
      return UserType.GlobalAdmin
    default:
      return UserType.Undefined
  }
};

export interface UserAddModel {
  User: UserModel;
  Password: UserPasswordAdminModel;
};

export interface UserModel {
  Id: string;
  Email: string;
  FirstName: string | null;
  LastName: string | null;
  Active: boolean;
  Type: UserType;
};

export interface UserPasswordModel {
  Id: string;
  CurrentPassword: string;
  Password: string;
  ConfirmPassword: string;
};

export interface UserPasswordAdminModel {
  Id: string;
  Password: string;
  ConfirmPassword: string;
};

class UserService {
  public async getAll(): Promise<ApiResponse<UserModel[]>> {
    return (await http.get<ApiResponse<UserModel[]>>('api/users/getAll')).data;
  };

  public async get(id: string): Promise<ApiResponse<UserModel>> {
    return (await http.get<ApiResponse<UserModel>>('api/users/get?id=' + id)).data;
  };

  public async add(model: UserAddModel): Promise<ApiResponse<any>> {
    return (await http.post<ApiResponse<any>>('api/users/add', model)).data;
  };

  public async update(model: UserModel): Promise<ApiResponse<any>> {
    return (await http.put<ApiResponse<any>>('api/users/update', model)).data;
  };

  public async updatePassword(model: UserPasswordModel): Promise<ApiResponse<any>> {
    return (await http.put<ApiResponse<any>>('api/users/updatePassword', model)).data;
  };

  public async updatePasswordAdmin(model: UserPasswordAdminModel): Promise<ApiResponse<any>> {
    return (await http.put<ApiResponse<any>>('api/users/updatePasswordAdmin', model)).data;
  };

  public async archive(id: string): Promise<ApiResponse<any>> {
    return (await http.put<ApiResponse<any>>('api/users/archive?id=' + id)).data;
  };
}

export default new UserService();