import React from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

type ErrorLocationState = {
  errorMessage?: string
}

export default function Error(props: RouteComponentProps<{}, StaticContext, ErrorLocationState>)
{
  return (
    <>
      <div className='container-page px-0'>
        <div className='d-flex justify-content-between flex-wrap mb-3 no-print'>
          <h2 className='mb-2'>Error</h2>
        </div>
      </div>
      {
        props.location.state && props.location.state.errorMessage != undefined ?
          <div className='container-page px-0'>
            <div className="alert alert-danger">
                <ul className="mb-0">
                  <li>{props.location.state.errorMessage}</li>
                </ul>
            </div>
          </div> :
          <></>
      }
    </>
  );
}