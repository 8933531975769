import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useStore } from '../../components/appProvider';
import { SessionAction } from '../../components/session/actions';
import { UserType } from '../../services/userService';

export default function Navigation() {
  const { state, dispatch } = useStore();

  const history = useHistory();

  function logOut() {
    dispatch({ type: SessionAction.Logout, payload: {} });
    history.push('/');
  }

  return (
    <nav id='MainNav' className='py-1 navbar navbar-expand-md navbar-dark sticky-top bg-dark no-print text-nowrap'>
      <div className='container-fluid'>
        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarCollapse' aria-controls='navbarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarCollapse'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item mr-2'><Link to='/' className='pl-0 nav-link'><i className='fa fa-map mr-1'></i> Map</Link></li>
            <li className='nav-item'><Link to='/users' className='pl-0 nav-link'><i className='fa fa-users mr-1'></i> Users</Link></li>
            {
              state.session.auth.userType == UserType.GlobalAdmin ?
                <li className='nav-item'><Link to='/admin' className='pl-0 nav-link'><i className='fa fa-cog mr-1'></i> Admin</Link></li> :
                <></>
            }
          </ul>
        </div>
        <div className='form-inline'>
          {
            state.session.isAuthenticated ?
              <>
                <span className='d-inline-block text-white'>
                    <span className='d-none d-md-inline-block'>
                      <span className='pr-2'>Hello,</span>
                    </span>
                    {state.session.auth.firstName}
                    {
                      state.session.auth.userType != UserType.GlobalAdmin ?
                      <Link to={`/users/details/${state.session.auth.userId}`} className='py-0 nav-link nav-custom-link d-inline-block'>
                        <i className='fa fa-user fa-fw'></i> <span className='d-none d-lg-inline-block'>Profile</span>
                      </Link> :
                      <span className='mx-2'></span>
                    }
                </span>
                <button onClick={logOut} className='btn btn-light btn-sm my-2 my-sm-0'><i className='fa fa-sign-out mr-2'></i>Logout</button>
              </> :
              <Link to='/login' className='btn btn-light btn-sm my-2 my-sm-0'><i className='fa fa-sign-in mr-2'></i>Login</Link>
          }
        </div>
      </div>
    </nav>
  );
}