import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import UserService, { UserPasswordModel } from '../../../services/userService';
import Notification from '../../../components/notification'
import Loading from '../../../components/loading';

interface IUserPasswordProps {
  userId: string,
  tabSelect: (key: any) => void
}

export default function UserPassword({ userId, tabSelect }: IUserPasswordProps) {
  const [isLoading, setIsLoading] = useState(false);

  const schema = Yup.object().shape({
    CurrentPassword: Yup.string().required('Current Password is required'),
    Password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    ConfirmPassword: Yup.string().required('Confirmed Password is required').min(8, 'Confirmed Password must be at least 8 characters')
      .test('passwords-match', 'Passwords must match', function(value) {
        return this.parent.Password === value;
      })
  });

  async function submit(values: any, { setErrors, setSubmitting }: any) {
    setIsLoading(true);

    await UserService.updatePassword({
      Id: userId,
      CurrentPassword: values.CurrentPassword,
      Password: values.Password,
      ConfirmPassword: values.ConfirmPassword
    } as UserPasswordModel)
      .then(() => { 
        Notification.Success('User password changed successfully'); 
        tabSelect('Settings');
      })
      .catch((response) => { 
        setErrors({ error: response.Error }); 
      })
      .finally(() => { 
        setIsLoading(false); 
        setSubmitting(false); 
      });
  }

  return (
    <>
      { isLoading ? <Loading /> : <></> }
      <div className='row mt-3'>
        <div className='col-xs-12 col-sm-10 col-md-8 col-lg-6'>
          <h3 style={{marginTop: '0', marginBottom: '20px'}}>
            Password
          </h3>
        </div>
      </div>
      <Formik
        initialValues={{ CurrentPassword: '', Password: '', ConfirmPassword: '' }}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={submit}
      >
        {({ isSubmitting, isValid, errors }) => (
          <Form className='forms'>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-8 col-lg-5'>
                <div className={`validation-summary-errors text-danger ${isValid ? 'd-none' : ''}`}>
                  <span>Please look at the error(s) below:</span>
                  <ul>
                    {Object.values(errors).map((msg, i) => (
                      <li key={i}>{msg}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-8 col-lg-5'>
                <div className='form-group'>
                  <label>Current Password</label>
                  <Field className='form-control text-box single-line' name='CurrentPassword' type='password' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-8 col-lg-5'>
                <div className='form-group'>
                  <label>Password</label>
                  <Field className='form-control text-box single-line' name='Password' type='password' autoComplete='new-password' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-8 col-lg-5'>
                <div className='form-group'>
                  <label>Confirm Password</label>
                  <Field className='form-control text-box single-line' name='ConfirmPassword' type='password' autoComplete='confirm-password' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-8 col-lg-6'>
                <div className='form-group pull-left'>
                  <button type='submit' disabled={isSubmitting} className='btn btn-primary btn-bold'>Save changes</button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}