import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useStore } from './appProvider';
import { UserType } from '../services/userService';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

interface IAuthorizedRouteProps extends RouteProps {
  userTypeList?: UserType[],
  forceRefresh?: boolean
}

export default function AuthorizedRoute({ userTypeList, forceRefresh, component: Component, ...rest }: IAuthorizedRouteProps) {
  const { state } = useStore();

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        !state.session.isAuthenticated ?
          <Redirect to={{ pathname: '/login', state: { referer: props.location } }} /> :
          userTypeList != undefined && _.indexOf(userTypeList, state.session.auth.userType) == -1 ?
            <Redirect to={{ pathname: '/error', state: { errorMessage: 'You do not have the required permission(s)' }}} /> :
              forceRefresh != undefined && forceRefresh == true ? 
                <Component {...props} key={ uuidv4() } /> : 
                  <Component {...props} />
      }
    />
  );
}