import { http, ApiResponse, ApiSimpleResponse } from './httpService';

export interface ClearanceModel {
  Id: string;
  Name: string;
};

class ClearanceService {
  public async getAll(): Promise<ApiResponse<ClearanceModel[]>> {
    return (await http.get<ApiResponse<ClearanceModel[]>>('api/clearances/getAll')).data;
  };
  public async addDefault(): Promise<ApiSimpleResponse> {
    return (await http.post<ApiSimpleResponse>('api/clearances/addDefault')).data;
  };
}

export default new ClearanceService();