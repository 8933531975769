import React, { useState } from 'react';
import UserService from '../../../services/userService';
import Notification from '../../../components/notification'
import Loading from '../../../components/loading';
import { useHistory } from 'react-router-dom';

interface IArchiveUserProps {
  userId: string,
  userName: string
}

export default function ArchiveUser({ userId, userName }: IArchiveUserProps) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  async function archiveUserClick() {
    setIsLoading(true);

    await UserService.archive(userId)
      .then(() => { 
        Notification.Success('User successfully archived'); 
      })
      .catch(() => { 
        Notification.Error('User archiving failed'); 
      })
      .finally(() => { 
        setIsLoading(false); 
        history.push('/users');
      });
  }

  return (
    <>
      { isLoading ? <Loading /> : <></> }
      <div className='row my-3'>
        <div className='col-xs-12 col-sm-10 col-md-8 col-lg-6'>
          <h3 style={{marginTop: '0', marginBottom: '20px'}}>
            Archive User
          </h3>
          <div className="form-group">
            Are you sure you want to archive {userName}?
          </div>
          <div className="form-group">
            <div className="pull-left">
              <button className="btn btn-danger" type="submit" onClick={archiveUserClick}> Archive User </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}