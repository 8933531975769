import React, { useState, useEffect } from 'react';
import { useStore } from '../../components/appProvider';
import Loading from '../../components/loading';

export default function Map()
{
  const { state } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => setIsLoading(false), 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {
        isLoading ? <Loading /> : <></>
      }
      <div className='container-page px-0'>
        <iframe
          title='Map'
          width='100%' 
          height='600px' 
          frameBorder='0'
          src={`https://${state.session.auth.geotabServer}/${state.session.auth.client}/geotab/checkmate/ui/map.html#credentials:(database:${state.session.auth.client},sessionId:${state.session.auth.geotabSessionId},userName:'${state.session.auth.email}'),liveVehicleIds:!(b1)`}>
        </iframe>
      </div>
    </>
  );
}