import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import { useStore } from '../../../components/appProvider';
import * as Yup from 'yup';
import UserService, { UserModel, UserType } from '../../../services/userService';
import Notification from '../../../components/notification'
import Loading from '../../../components/loading';

interface IUserSettingsProps {
  user: UserModel
}

export default function UserSettings({ user }: IUserSettingsProps) {
  const { state } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isAdmin = state.session.auth.userType == UserType.GeotabAdmin || state.session.auth.userType == UserType.GlobalAdmin;

  const schema = Yup.object().shape({
    Type: Yup.string().required('Permission Group is required'),
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('Last Name is required')
  });
  
  async function submit(values: any, { setErrors, setSubmitting }: any) {
    setIsLoading(true);

    await UserService.update({
      Id: user.Id,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Type: values.Type
    } as UserModel)
      .then(() => {
        Notification.Success('User saved successfully');
        history.push(isAdmin ? '/users' : '/users/details/' + user.Id);
      })
      .catch((response) => { 
        setErrors({ error: response.Error }); 
      })
      .finally(() => { 
        setIsLoading(false); 
        setSubmitting(false); 
      });
  }

  return (
    <>
      { isLoading ? <Loading /> : <></> }
      <div className='row mt-3'>
        <div className='col-xs-12 col-sm-10 col-md-6 col-lg-6'>
          <h3 style={{marginTop: '0', marginBottom: '20px'}}>
            Settings
          </h3>
        </div>
      </div>
      <Formik
        initialValues={{ UserName: user.Email, Type: user.Type, FirstName: user.FirstName, LastName: user.LastName }}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={submit}
      >
        {({ isSubmitting, isValid, errors }) => (
          <Form className='forms'>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-6 col-lg-5'>
                <div className={`validation-summary-errors text-danger ${isValid ? 'd-none' : ''}`}>
                  <span>Please look at the error(s) below:</span>
                  <ul>
                    {Object.values(errors).map((msg, i) => (
                      <li key={i}>{msg}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-6 col-lg-5'>
                <div className='form-group'>
                  <label>User</label>
                  <Field className='form-control text-box single-line' name='UserName' type='text' disabled />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-6 col-lg-5'>
                <div className='form-group'>
                  <label>Permission Group</label>
                  <Field as='select' className='custom-select form-control' name='Type' disabled={isAdmin ? false : true}>
                    <option value=''></option>
                    <option value='1'>Admin</option>
                    <option value='2'>Driver</option>
                    <option value='3'>Dispatcher</option>
                  </Field>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-6 col-lg-5'>
                <div className='form-group'>
                  <label>First Name</label>
                  <Field className='form-control text-box single-line' name='FirstName' type='text' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-6 col-lg-5'>
                <div className='form-group'>
                  <label>Last Name</label>
                  <Field className='form-control text-box single-line' name='LastName' type='text' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-10 col-md-6 col-lg-6'>
                <div className='form-group pull-left'>
                  <button type='submit' disabled={isSubmitting} className='btn btn-primary btn-bold'>Save changes</button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}