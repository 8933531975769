import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loading';
import UserService, { UserModel, UserPasswordModel, UserAddModel, UserType } from '../../services/userService';
import Notification from '../../components/notification'

export default function AddUser()
{
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const schema = Yup.object().shape({
  Email: Yup.string().required('Username is required').min(2, 'Username must be at least 2 characters')
      .test('no-email-for-drivers', 'Username must not be an email for drivers', function(value) {
        return !(this.parent.Type === String(UserType.GeotabDriver) && this.parent.Email.includes('@'));
      }),
    Type: Yup.string().required('Permission Group is required'),
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('Last Name is required'),
    Password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    ConfirmPassword: Yup.string().required('Confirmed Password is required').min(8, 'Confirmed Password must be at least 8 characters')
      .test('passwords-match', 'Passwords must match', function(value) {
        return this.parent.Password === value;
      })
  });

  async function submit(values: any, { setErrors, setSubmitting }: any) {
    setIsLoading(true);

    await UserService.add({
      User: {
        FirstName: values.FirstName,
        LastName: values.LastName,
        Type: values.Type,
        Email: values.Email
      } as UserModel,
      Password: {
        Password: values.Password,
        ConfirmPassword: values.ConfirmPassword
      } as UserPasswordModel
    } as UserAddModel)
      .then(() => {
        Notification.Success('User added successfully');
        history.push('/users');
      })
      .catch((response) => {
        setErrors({ error: response.Error }); 
      })
      .finally(() => { 
        setIsLoading(false); 
        setSubmitting(false); 
      });
  }

  return (
    <>
      { isLoading ? <Loading /> : <></> }
      <div className='container-page px-0'>
        <div className='row mt-3'>
          <div className='col-xs-12 col-sm-10 col-md-6 col-lg-6'>
            <h3 style={{marginTop: '0', marginBottom: '20px'}}>
              Add User Account
            </h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-header'>
                Information
              </div>
              <div className='card-body'>
                <Formik
                  initialValues={{ Email: '', Type: '', FirstName: '', LastName: '', Password: '', ConfirmPassword: '' }}
                  validationSchema={schema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={submit}
                >
                  {({ isSubmitting, isValid, errors }) => (
                    <Form className='forms'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className={`validation-summary-errors text-danger ${isValid ? 'd-none' : ''}`}>
                            <span>Please look at the error(s) below:</span>
                            <ul>
                              {Object.values(errors).map((msg, i) => (
                                <li key={i}>{msg}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xs-12 col-md-6 col-lg-6'>
                          <div className='form-group'>
                            <label>Username</label>
                            <Field className='form-control text-box single-line' name='Email' type='text' />
                          </div>
                        </div>
                        <div className='col-xs-12 col-md-6 col-lg-6'>
                          <div className='form-group'>
                            <label>Permission Group</label>
                            <Field as='select' className='custom-select form-control' name='Type'>
                              <option value=''></option>
                              <option value='1'>Admin</option>
                              <option value='2'>Driver</option>
                              <option value='3'>Dispatcher</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xs-12 col-md-6 col-lg-6'>
                          <div className='form-group'>
                            <label>First Name</label>
                            <Field className='form-control text-box single-line' name='FirstName' type='text' />
                          </div>
                        </div>
                        <div className='col-xs-12 col-md-6 col-lg-6'>
                          <div className='form-group'>
                            <label>Last Name</label>
                            <Field className='form-control text-box single-line' name='LastName' type='text' />
                          </div>
                        </div>
                      </div>
                      <div className='row mt-3'>
                        <div className='col-xs-12 col-md-6 col-lg-6'>
                          <div className='form-group'>
                            <label>Password</label>
                            <Field className='form-control text-box single-line' name='Password' type='password' autoComplete='new-password' />
                          </div>
                        </div>
                        <div className='col-xs-12 col-md-6 col-lg-6'>
                          <div className='form-group'>
                            <label>Confirm Password</label>
                            <Field className='form-control text-box single-line' name='ConfirmPassword' type='password' autoComplete='confirm-password' />
                          </div>
                        </div>
                      </div>
                      <div className='pull-right'>
                        <button type='submit' disabled={isSubmitting} className='btn btn-primary btn-bold'>Add User</button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}