import Logo from '../../images/logo_lt.png';

import React from 'react';

export default function Header() {
  return (
    <header className='header-bg sticky-top no-print'>
      <div className='header-img container-fluid'>
          <div className='row flex-nowrap justify-content-between align-items-center mx-0'>
              <div className='py-2 px-2 text-center'>
                  <img className='img-fluid' src={Logo} alt='mpaq logo' />
              </div>
              <div className='col-6 text-center'>
                  <h4 className='mb-0'>
                      <span className='d-none d-sm-inline-block'>Round Trip GPS</span>
                  </h4>
              </div>
          </div>
      </div>
    </header>
  );
}