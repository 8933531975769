import { utc } from 'moment';
import { Session, saveSession, clearSession } from './session';
import { getDefaultToken } from './token';
import { SessionAction, SessionActions } from './actions';
import { UserTypeParse } from '../../services/userService';

export type InitialStateType = {
  session: Session;
};

export const SessionReducer = (state: Session, action: SessionActions) => {
  switch (action.type) {
    case SessionAction.Login:
      state.auth = action.payload.token;
      state.expiry = utc().add(action.payload.token.expires_in, 'seconds');
      state.rememberMe = action.payload.rememberMe;
      state.isAuthenticated = true;
      state.auth.userType = UserTypeParse(state.auth.userType);

      saveSession(state);

      return state;
    case SessionAction.Logout:
      state.auth = getDefaultToken(state.rememberMe ? state.auth.email : null);
      state.expiry = null;
      state.isAuthenticated = false;
      
      clearSession(state);

      return state;
    default:
      return state;
  }
};